import React from 'react'

// Components
import { Link, graphql } from 'gatsby'
import SEO from '../components/seo'
import Toolbar from '../components/toolbar'
import { formatPostDate, formatReadingTime } from '../utils/dates'
import Pills from '../components/pills'
import Img from 'gatsby-image'
import Section from '../components/section'
import Footer from '../components/footer'

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges } = data.allMdx

  return (
    <div>
      <Toolbar/>
      <SEO title={`Article avec comme catégorie « ${tag} »`}/>
      {edges.map(({ node }) => {
        return (
          <Section key={node.fields.slug} name={node.fields.slug} centered>
            <Link to={node.fields.slug} className="blog-listing">
              <h1>{node.frontmatter.title}</h1>
              <p>
                {formatPostDate(node.frontmatter.date)}{` − ${formatReadingTime(node.timeToRead)}`}
              </p>

              <Pills items={node.frontmatter.categories}/>

              <br/>
              {node.frontmatter.cover &&
              <Img fluid={node.frontmatter.cover.childImageSharp.sizes}/>
              }

              <p>{node.frontmatter.description}</p>
            </Link>
          </Section>
        )
      })}

      <Footer />
    </div>
  )
}

export default Tags

export const pageQuery = graphql`
    query($tag: String) {
        allMdx(
            limit: 2000
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { categories: { in: [$tag] } } }
        ) {
            edges {
                node {
                    fields {
                        slug
                    }
                    timeToRead
                    frontmatter {
                        title
                        description
                        categories
                        cover {
                            childImageSharp {
                                sizes(maxWidth: 630) {
                                    ...GatsbyImageSharpSizes
                                }
                            }
                        }
                        date(formatString: "MMMM DD, YYYY")
                    }
                }
            }
        }
    }
`
